import React from "react";
import { Link } from "react-router-dom";
import "./SitemapPage.css";

const SitemapPage = () => {
  return (
    <div className="sitemap-page">
      <h1>Site Map</h1>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About Us</Link>
        </li>
        <li>
          <Link to="/guide">Read Our Guide</Link>
        </li>
        <li>
          <Link to="/banking-guide">
            Min Max Banking Churning Guide for a Working Young Adult (2024 Canadian Edition)
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SitemapPage;
