import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
import "./BankingGuidePage.css";
import { Helmet } from "react-helmet";
import Analysis from "../Components/Analysis";

const guideSections = [
  {
    title: <p>Bank of Montreal</p>,
    description: (
      <p>
        Open a BMO's Performance Chequing Account and earn <b>$350</b> and open a BMO's saving account and earn
        <b> $250</b>. Terms and Conditions apply.
      </p>
    ),
    link: "https://www.bmo.com/en-ca/main/personal/bank-accounts/chequing-accounts/performance/",
  },
  {
    title: <p>Simplii Financial</p>,
    description: (
      <p>
        Open a Simplii's no fee Chequing Account and earn <b>$400</b>. Earn an additional <b>$50</b> from a friend
        referral. Terms and Conditions apply. If you would like to use my referral:
        <a href="https://blue.mbsy.co/6DBWRL"> Referral Link </a>
      </p>
    ),
    link: "https://www.simplii.com/en/special-offers/no-fee-chequing-account.html",
  },
  {
    title: <p>Royal Bank of Canada</p>,
    description: (
      <p>
        Open a RBC Signature No Limit Banking account and earn an Ipad up to <b>$600</b>. Terms and Conditions apply.
      </p>
    ),
    link: "https://www.rbcroyalbank.com/dms/pba/open-an-account/offer-n-or.html",
  },
  {
    title: <p>Tangerine</p>,
    description: (
      <p>
        Open a no monthly fee Chequing Account with Tangerine and earn <b>$250</b>. Terms and Conditions apply.
      </p>
    ),
    link: "https://www.tangerine.ca/en/offers/earn-more",
  },
  {
    title: <p>TD Bank</p>,
    description: (
      <p>
        Open a TD Unlimited Chequing Account and earn <b>$400</b> and open a TD Saving Account and earn <b>$200</b>.
        Terms and Condition apply.
      </p>
    ),
    link: "https://www.td.com/ca/en/personal-banking/new-bank-account-offers-promotions",
  },
  {
    title: <p>CIBC</p>,
    description: (
      <p>
        Open a CIBC Smart Account and earn <b>$400</b> and open a CIBC eAvantage Savings Account to earn <b>$200</b>.
        Terms and Condition Apply.
      </p>
    ),
    link: "https://www.cibc.com/en/special-offers/smart-cash-offer.html?itrc=M825:1",
  },
  {
    title: <p>ScotiaBank</p>,
    description: (
      <p>
        Open a Scotiabank Ultimate or Preferred Package account and earn <b>$350</b>. Additionally, earn an extra{" "}
        <b>$50</b> when you opena new RRSP,TFSA, or FHSA. Terms and Conditions Apply.
      </p>
    ),
    link: "https://www.scotiabank.com/ca/en/personal/bank-accounts/chequing-accounts/ultimate-package.html",
  },
  {
    title: <p>Wealthsimple</p>,
    description: (
      <p>
        Open a cash account and get <b>1% Cash back</b> on all deposits. Additionally, earn <b>$25</b> on a referral
        offer. Terms and Conditions apply. If you would like to use my referral, my referral code is: YYK3MG
      </p>
    ),
    link: "https://www.wealthsimple.com/invite",
  },
];

const BankingGuidePage = () => {
  return (
    <div className="blog-page">
      <Helmet>
        <title>MinMax Banking Guide for Young Adult (Canadian Editions)</title>
        <meta
          name="description"
          content="Explore our comprehensive banking guide to learn about how to churn bank accounts and maximize your returns."
        />
      </Helmet>
      <h1>Min Max Banking Churning Guide for a Working Young Adult (2024 Canadian Edition)</h1>
      <p>
        Welcome to the comprehensive guide on maximizing your money with banking. Managing your finances effectively can
        significantly impact your financial health and future. This guide is designed to help you maximize your money
        through strategic use of churning bank accounts. Through this guide, you can
        <b> potentially earn over $2500 value for the year</b>... just from opening bank accounts!
      </p>

      <p>
        For those that already know what they are doing, below is a list of all the offers that are currently being
        offered by Major Banks in Canada. Some links provided may contain referral offer (as they are needed to achieve
        the maximum offer rewards):
      </p>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Bank</b>
              </TableCell>
              <TableCell>
                <b>Quick Description</b>
              </TableCell>
              <TableCell>
                <b>Offer Link</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guideSections.map((section) => (
              <TableRow key={section.title}>
                <TableCell component="th" scope="row">
                  {section.title}
                </TableCell>
                <TableCell>{section.description}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    component="a"
                    color="primary"
                    href={section.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ backgroundColor: "green", color: "white", "&:hover": { backgroundColor: "darkgreen" } }}
                  >
                    Go to Offer
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h1>Beginners Guide</h1>
      <p>
        <b>
          *Note: This guide is for people who are new to banking. Many of the offers will not work if the user already
          has an account with a bank/broker.
        </b>
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#introduction">Introduction</a>
          </li>
          <li>
            <a href="#analysis">Detailed analysis on offers</a>
          </li>
          <li>
            <a href="#choosing-a-bank">Choosing which banks to open</a>
          </li>
          <li>
            <a href="#conclusion">Conclusion</a>
          </li>
        </ul>
      </p>

      <h1 id="introduction">Introduction</h1>
      <p>
        This is a comprehensive guide that helps users earn as much money as possible from opening bank accounts. This
        guide is intended for users who are starting out, especially those who do not have alot of capital (which this
        method will help them jump start their saving and investment career)
      </p>
      <p>
        Firstly, lets understand some <b>terminologies </b>. If you already know all of them, then skip to the next
        section.
        <h2>What is a Bank Account?</h2>
        <p>
          A bank account is a financial account maintained by a bank or other financial institution for a customer. It
          allows the customer to deposit and withdraw money and provides a record of the transactions conducted. Here
          are some key points about bank accounts:
        </p>
        <h3>Types of Bank Accounts</h3>
        <p> There are more types of accounts out there, but these 2 are the most widely popular ones:</p>
        <ul>
          <li>
            <strong>Checking Account</strong>: Used for everyday transactions. It allows for easy access to funds
            through checks, debit cards, and online transfers.
          </li>
          <li>
            <strong>Savings Account</strong>: Designed for saving money. It typically offers interest on the deposited
            funds but may have limited withdrawal options.
          </li>
        </ul>
      </p>
      <h2>Banking Fees Explained</h2>
      <p>
        Banking fees are charges imposed by banks on their customers for various services and activities. These fees can
        vary widely depending on the bank, the type of account, and the specific service or transaction. All accounts
        have some sort of fees for services, however, some services are free of charge. Here are some common types of
        banking fees:
      </p>

      <h3>Common Types of Banking Fees</h3>
      <ul>
        <li>
          <strong>Monthly Maintenance Fees</strong>: Charged for maintaining the account. Often waived if certain
          conditions are met, such as maintaining a minimum balance or setting up direct deposits.
        </li>
        <li>
          <strong>ATM Fees</strong>: Charged for using an out-of-network ATM. Both the bank and the ATM operator may
          charge a fee.
        </li>
        <li>
          <strong>Overdraft Fees</strong>: Charged when you withdraw more money than you have in your account. Some
          banks offer overdraft protection, which may have its own fees.
        </li>
        <li>
          <strong>Insufficient Funds Fees (NSF)</strong>: Charged when a transaction is declined due to insufficient
          funds. Different from overdraft fees, which apply when the bank covers the overdrawn amount.
        </li>
        <li>
          <strong>Transaction Fees</strong>: Applied to certain types of transactions, such as wire transfers or
          excessive withdrawals from a savings account. Can include fees for foreign transactions if you use your debit
          card abroad.
        </li>
        <li>
          <strong>Account Closing Fees</strong>: Charged if you close your account within a certain period after opening
          it.
        </li>
        <li>
          <strong>Paper Statement Fees</strong>: Charged if you opt to receive paper statements instead of electronic
          ones.
        </li>
        <li>
          <strong>Service Fees</strong>: Applied to various services like stop payments, check printing, or cashier’s
          checks.
        </li>
      </ul>
      <h3 class="important">
        IMPORTANT: It is instrumental that you minimize or straight up do not incur any fees that are charged by the
        bank in order to maximize your return.
      </h3>
      <h3>IMPORTANT: Still a WIP</h3>
      <h1 id="analysis">Analysis</h1>
      <p>
        In this section, we will go into detail on each of the individual bank offers. In each offer, I will weigh my
        pros and cons, as well as my thoughts on the value of the offer.
      </p>
      <Analysis></Analysis>
      {/* <h2 id="choosing-a-bank">Choosing which banks to open</h2>
      <p>...</p>

      <h2 id="conclusion">Conclusion</h2>
      <p>...</p> */}
    </div>
  );
};

export default BankingGuidePage;
