import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  CardActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AnalysisCard = ({ title, pros, cons, details, analysis }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card variant="outlined" style={{ marginBottom: "20px" }}>
      <CardActions onClick={handleExpandClick} disableSpacing>
        <Typography variant="h5" component="div" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <ExpandMore expand={expanded} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Divider />
          <Typography variant="h6">Bank Offer Details</Typography>
          <Typography variant="body2" component="div" style={{ paddingLeft: "16px" }}>
            {details}
          </Typography>
          <Divider />
          <Typography>Pros</Typography>
          <List>
            {pros.map((pro, index) => (
              <ListItem key={index}>
                <ListItemText primary={pro} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <Typography variant="h6">Cons</Typography>
          <List>
            {cons.map((con, index) => (
              <ListItem key={index}>
                <ListItemText primary={con} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <Typography variant="h6">Thoughts</Typography>
          <Typography variant="body2" component="div" style={{ paddingLeft: "16px" }}>
            {analysis}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default AnalysisCard;
