import React from "react";
import { Link } from "react-router-dom";
import "./AboutPage.css"; // Import CSS file for styles
import image from "../assets/Vancouver.jpg";

const AboutPage = () => {
  return (
    <div className="about-page">
      <h1>About Me</h1>
      <img src={image} alt="Vancouver Downtown" className="landing-page-image" />
      <p>
        Im a student from the University of British Columbia! Housing prices here are absurd and pretty much everyone
        who even wants to afford a home here to need starts saving and investing early to even stand a chance. I spend
        lots of time researching on how to get the best value for my money and I post guides here so that I can help
        others do the same.
      </p>
    </div>
  );
};

export default AboutPage;
