import React from "react";
import { Link } from "react-router-dom";
import "./GuidePage.css";

const GuidePage = () => {
  return (
    <div className="guide-page">
      <h1>Guide</h1>
      <p>Banking Guides</p>
      <ul>
        <li>
          <Link to="/banking-guide">Min Max Banking Guide for a Working Young Adult</Link>
        </li>
      </ul>
    </div>
  );
};

export default GuidePage;
