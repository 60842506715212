import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./LandingPage.css";
import image from "../assets/Landing_Page.jpg";

const LandingPage = () => {
  return (
    <div className="landing-page">
      <Helmet>
        <title>Welcome to a very Scrappy Financial Guide!</title>
        <meta name="description" content="Maximize your financials with our guides tailored for young adults." />
      </Helmet>
      <h1>Welcome to a very Scrappy Financial Guide!</h1>
      <img src={image} alt="Financial Guide" className="landing-page-image" />
      <p>
        Are you someone who has a bit of time and want to maximize your financials? Are you a Young Adult that are just
        getting into finance? Then this is the place that is right for you! Check out the guides to get started!
      </p>
      <Link to="/about">About Us</Link>
      <br />
      <Link to="/guide">Read Our Guide</Link>
      <br />
      <Link to="/sitemap">Site Map</Link>
    </div>
  );
};

export default LandingPage;
