import React from "react";
import AnalysisCard from "./AnalysisCard";

const Analyses = () => {
  const analyses = [
    {
      title: <b>Bank of Montreal</b>,
      pros: ["$550 cash offer is big", "In person branch is widely available"],
      cons: ["Monthly fee without minimum balance of $4000", "Need to hold money in chequeing for about a year"],
      details: (
        <div>
          <p>
            Earn $350 when you open a new BMO Performance or Premium Plan Chequing Account and make a deposit of any
            amount. Complete two out of the three following:
            <ul>
              <li>Set up a recurring direct deposit</li>
              <li>Make two eligible online bill payments of at least $50 to different billers</li>
              <li>Set up two eligible pre-authorized debits of at least $50 to different billers</li>
            </ul>
          </p>
          <p>
            Earn $200 when you open a Savings Amplifier Account and complete the following:
            <ul>
              <li>
                Deposit a total $10,000 or more into the Savings Amplifier Account within the first 30 calendar days of
                opening the Savings Amplifier Account
              </li>
              <li>
                After the initial 30-calendar day period from the Savings Amplifier Account open date, maintain a daily
                closing balance of $10,000 or more at all times for an additional 90 calendar days
              </li>
            </ul>
          </p>
        </div>
      ),
      analysis: (
        <div>
          <p>Though the BMO offer is big, it requires lots of capital to get all the cash offer.</p>
          <p>
            For chequeing account, you will need $4000 minimum balance else you will incur a monthly fee. This is a{" "}
            <b>major downside</b>, as you will have to park $4000 into something that cannot generate any interest.
          </p>
          <p>
            For the saving account, there is a minimum of $10000 deposit to get the $200 offer. There are promotional
            interest rates for when you deposit the money (Last time I check it was about 5.5% which is quite
            substantial and is good enough to replace investment)
          </p>
          <p>
            To calculate the actual value of the offer, I will subtract 1 year worth interest (5%) for the $4000 minimum
            balance. 4000 * 0.05 = $200. So in total, this offer is valued at 550 - 200 = $350 base on $14000 put into
            the accounts.
          </p>
        </div>
      ),
    },
    {
      title: <b>Simplii Financial</b>,
      pros: ["$400 cash offer is big", "Easy to set up"],
      cons: ["Web UI and app are a bit clunky", "No in branch services"],
      details: (
        <div>
          <p>
            Earn $400 when you open a No Fee Chequing Account. Add an eligible direct deposit of at least $100 a month
            for 3 straight months.
          </p>
        </div>
      ),
      analysis: (
        <div>
          <p>In my opinion, Simplii's offer is probably the best one out of all the Canadian finance instituions</p>
          <p>
            The only condition is that you must change your direct deposit. However, you are able to easily split your
            direct deposit as Simplii only requires that you deposit $100. Theres no minimum balance fee which makes
            this an extremely easy free cash offer to option.
          </p>
        </div>
      ),
    },
    {
      title: <b>Royal Bank of Canada</b>,
      pros: ["$600 in value is really good!", "In person branch service"],
      cons: ["Reward is an Ipad, not cash which can be invested", "Monthly fee eats into the reward value"],
      details: (
        <div>
          <p>
            Open a RBC Signature No Limit Banking account and earn an Ipad up to $600. Recieve the reward by completing
            two out of the three following:
            <ul>
              <li>Set up payroll or pension direct deposit (RBC T&C requires full deposit)</li>
              <li>Make two eligible online bill payments of at least $50 to different billers</li>
              <li>Set up two eligible pre-authorized debits of at least $50 to different billers</li>
            </ul>
          </p>
        </div>
      ),
      analysis: (
        <div>
          <p>
            At first RBC's reward looks decent. $600 dollar in value is OK however, the monthly fee of the account eats
            into the reward value.
          </p>
          <p>
            For chequeing account, you will be charged $16.95 per month and you will need to have the account open for
            about a minimum of 1 year, otherwise will they will retract the reward. The annual fee of the account
            amounts to $203.4 and deducting the reward amount, results in 600-203.4= $396.6 in value. Not bad, however,
            it is locked into an Ipad and I would only recommend you open this if you have use for an Ipad.
          </p>
        </div>
      ),
    },
    {
      title: <b>Tangerine</b>,
      pros: ["Easy to set up"],
      cons: ["$250 is on the low end", "No branch location"],
      details: (
        <div>
          <p>Open a no monthly fee Chequing Account and move your pay and you can earn $250</p>
        </div>
      ),
      analysis: (
        <div>
          <p>
            Tangerine offer is effectively a discount version of the Simplii's Financial offer. The cash offer is lower,
            but you can still easily set it up.
          </p>

          <p>
            Like Simplii's Financial the only condition is that you must change your direct deposit. The minimum direct
            deposit amount is $200 and you will have to do it for 2 months. Theres no minimum balance fee which makes
            this an extremely easy free cash offer option.
          </p>
        </div>
      ),
    },
    {
      title: <b>TD Bank</b>,
      pros: ["Well known bank", "In branch service"],
      cons: ["Monthly fee without minimum balance of $4000", "Need to hold money in chequeing for about a year"],
      details: (
        <div>
          <p>
            Get $400 by opening a TD All-Inclusive Banking Plan or TD Unlimited Chequing Account and complete two of
            following by:
            <ul>
              <li>Set up a recurring direct deposit</li>
              <li>Make two eligible online bill payments of at least $50 to different billers</li>
              <li>Set up two eligible pre-authorized debits of at least $50 to different billers</li>
            </ul>
          </p>
          <p>
            Get $200 by depositing $10,000 into the new eligible Savings Account within 30 days of account opening and
            maintain the balance for 150 days. Explore additional cash offers with the following deposit tiers. Earn:
            <ul>
              <li>$50 for $2,500 deposit</li>
              <li>$100 for $5,000 deposit</li>
              <li>$200 for $10,000 deposit</li>
              <li>$500 for $25,000 deposit</li>
              <li>$750 for $50,000 deposit</li>
              <li>$1,000 for $100,000+ deposit</li>
            </ul>
          </p>
        </div>
      ),
      analysis: (
        <div>
          <p>
            TD offer for chequeing is pretty good for its chequing account. However, for its saving account offer, it is
            in my opinion, a bait as you can earn more money parking your cash else where.
          </p>
          <p>
            For chequeing account, you will need $4000 minimum balance else you will incur a monthly fee. This is a{" "}
            <b>major downside</b>, as you will have to park $4000 into something that cannot generate any interest.
          </p>

          <p>
            For the saving account, there is a minimum of $2500 deposit to get the $50 offer. There are currently{" "}
            <b>No promotional interest rates</b> so your money inside it will be earning a pitiful amount. You need to
            maintain the money inside for 150 days. About 5 months of interest. I will use the average of 5% interest
            rate to calculate possible return from just investing it. With a 5% interest, 2500 will net you about $125 a
            year and $52.08 a year. This is lower than the $50 dollar offer that they give you. Even accounting for the
            small 0.05% interest in it, it is not worth the effort to open something that basically makes you lose
            money.
          </p>
          <p>
            As a result, I would recommend you only open the chequing account for its offer, If you want a bit more
            cash. To calculate the actual value of the offer, I will subtract 1 year worth interest (5%) for the $4000
            minimum balance. 4000 * 0.05 = $200. So in total, this offer is valued at 400 - 200 = $200.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div className="App" style={{ margin: "20px" }}>
      {analyses.map((analysis, index) => (
        <AnalysisCard
          key={index}
          title={analysis.title}
          pros={analysis.pros}
          cons={analysis.cons}
          details={analysis.details}
          analysis={analysis.analysis}
        />
      ))}
    </div>
  );
};

export default Analyses;
