import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/NavBar";
import LandingPage from "./Pages/LandingPage";
import AboutPage from "./Pages/AboutPage";
import GuidePage from "./Pages/GuidePage";
import BankingGuidePage from "./Pages/BankingGuidePage";
import SitemapPage from "./Pages/SitemapPage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCd2Q7vgIl1MfTmtJja5hRIwtTPCOXLn_0",
  authDomain: "financialblog-2d952.firebaseapp.com",
  projectId: "financialblog-2d952",
  storageBucket: "financialblog-2d952.appspot.com",
  messagingSenderId: "1066099001151",
  appId: "1:1066099001151:web:a8790f617149a6bb77758a",
  measurementId: "G-9CDZRJJ9VC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <Router>
      <div>
        <Navbar></Navbar>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/guide" element={<GuidePage />} />
          <Route path="/banking-guide" element={<BankingGuidePage />} />
          <Route path="/sitemap" element={<SitemapPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
